import { PublishContext } from 'context/publish/publish.provider';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Popover from '../atoms/popover/popover';
import { IListLocation } from 'interface/ILocation';
import { Button, Input } from '../atoms';
import { IPublishType } from 'context/publish/publish.reducer';
import { Buy, ChevronDown, Delete } from 'react-iconly';
import { ISchedulerApiSubmit } from 'interface/IScheduler';
import { Campaigns } from '@api/campaigns';
import { ILocationReach } from 'interface/ILocationReach';
import { Dialog } from '.';

type PublishMapListProps = {
  onClick?: (location: IListLocation) => {};
  onRemove?: (location: IListLocation) => {};
  source?: string;
};

const ListScreens: React.FC<PublishMapListProps> = ({ onClick, onRemove, source }: PublishMapListProps) => {
  const { publishState, dispatch } = React.useContext(PublishContext);
  const [locations, setLocations] = React.useState<IListLocation[]>();
  const [deleteList, setDeleteList] = React.useState<string[]>([]);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [filterString, setFilterString] = React.useState<string>('');
  const intl = useIntl();

  useEffect(() => {
    // setLocations(publishState.locations);
    filterLocation(filterString);
  }, []);

  useEffect(() => {
    filterLocation(filterString);
    // setLocations(publishState.locations);
  }, [publishState.locations]);

  const filterLocation = (e: string) => {
    setFilterString(e.toLocaleLowerCase());
    if (publishState.locations) {
      const searchLocation = publishState.locations.filter(el => {
        return el.location.n.toLocaleLowerCase().includes(e.toLocaleLowerCase());
      });
      setLocations(searchLocation);
    }
  };

  const deleteElem = (el: any) => {
    let l = [...deleteList];
    l.push(el.location.uuid);

    dispatch({
      type: IPublishType.removeLocation,
      payload: {
        mapLocations: l,
      },
    });
    setDeleteList([]);
    if (onRemove) {
      onRemove(el);
    }
  };

  const gotToLocation = (el: IListLocation) => {
    if (onClick) {
      onClick(el);
    }
  };

  const primaryButton = {
    buttonText: 'Confirm',
    action: () => {
      if (locations && locations[0]) deleteElem(locations[0]);
      setDialogOpen(false);
    },
  };

  const secondaryButton = {
    buttonText: 'Cancel',
    action: () => {
      setDialogOpen(false);
    },
  };

  return (
    <div className="p-4 w-80">
      {(dialogOpen && (
        <Dialog
          description={
            "Please note that if you remove this location, you'll be redirected to the location selection step because the basket will be empty"
          }
          title={'Empty Basket'}
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
        />
      )) ??
        null}
      <h2 className="font-semibold md:text-sm text-lightGrey dark:text-dark-400">
        <FormattedMessage id="Text.YourBasket" />
      </h2>
      <div className="space-y-10 pt-2 pb-4">
        <Input
          name="searcg"
          data-cy="basketSearch"
          type="text"
          borderLight={true}
          startIcon="Search"
          onChange={(e: any) => {
            filterLocation(e.target.value);
          }}
          label={''}
          placeholder={intl.formatMessage({
            defaultMessage: 'Search',
            id: 'Label.Search',
          })}
          fullWidth={true}
        />
      </div>
      {locations?.length ? (
        <>
          {locations?.map((el, index: number) => (
            <div
              className="flex w-full pb-2 mb-5 space-x-6 border-b border-grey dark:border-b-bordercolordark dark:hover:border-b-primary-default cursor-pointer"
              key={index}
              onClick={() => gotToLocation(el)}
            >
              <div className="w-16" onClick={() => gotToLocation(el)}>
                <img
                  className="object-cover w-16 h-16 rounded-lg"
                  src={`https://i.seeblindspot.com/thumb/${
                    el.location.locationImages > 0 ? el.location.uuid : el.location.networkUuid
                  }/00`}
                />
              </div>
              <div className="flex-1" onClick={() => gotToLocation(el)} data-cy={`basketItem${index}`}>
                <h4 className="text-sm font-semibold text-black dark:text-white">{el.location.n}</h4>
                <p className="pt-2 text-xs font-medium text-lightGrey dark:text-dark-400">
                  {' '}
                  <FormattedMessage id="Label.Address" /> {el.location.address}
                </p>
                {/* <p className="text-xs ">PPH: {3600 / el.pph}</p> */}
              </div>
              <div
                className="flex items-center justify-center w-1/12 cursor-pointer text-lightGrey dark:text-dark-400 hover:border rounded-xl hover:border-lightGrey dark:hover:border-dark-400"
                data-cy={`basketItem${index}Delete`}
                onClick={e => {
                  if ((publishState?.locations && publishState?.locations.length > 1) || source === 'map') {
                    deleteElem(el);
                    e.stopPropagation();
                  } else {
                    setDialogOpen(true);
                    e.stopPropagation();
                  }
                }}
              >
                <Delete set="light" />
              </div>
            </div>
          ))}
          {locations?.length > 10 && (
            <div>
              <Button
                onClick={() => {
                  dispatch({
                    type: IPublishType.resetLocations,
                    payload: {},
                  });
                }}
                color="secondary"
                className={` sm:w-full sm:mx-3`}
              >
                Reset
              </Button>
            </div>
          )}
        </>
      ) : (
        <div className="mt-2 text-sm font-medium text-dark-defaul dark:text-dark-300">
          Please add at least one location in order to proceed.
        </div>
      )}
      {/* <div className="w-full">
        {deleteList.length > 0 && (
          <div className="flex justify-end w-full">
            <Button color="danger" fullWidth={true} onClick={removeItems}>
              <FormattedMessage id="Text.Remove" /> ({deleteList.length})
            </Button>
          </div>
        )}
      </div> */}
    </div>
  );
};

const PublishMapList: React.FC<PublishMapListProps> = ({ onClick, onRemove, source }: PublishMapListProps) => {
  const { publishState, dispatch } = React.useContext(PublishContext);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (publishState.locations) setTotal(publishState.locations?.length);
    else setTotal(0);
  }, [publishState.locations]);

  return (
    <div>
      <Popover
        className="flex items-center justify-center w-full h-full px-5 rightbottom chevron-transform md:w-auto basket"
        handler={
          <div className="relative flex items-center font-semibold text-center cursor-pointer text-lightGrey">
            <div className="dark:text-dark-400 text-lightGrey">
              <Buy set="light" />
            </div>
            <div className="pl-2.5 hidden text-lg md:text-base dark:text-dark-400 basket-label-visibility">
              <FormattedMessage id="Text.Basket" />
            </div>
            {total > 0 && (
              <div className="hidden count-basket-label-visibility">
                <div className="bubble-gradient text-white w-7 h-7 leading-7 ml-2.5 rounded-full bg-gradient-red dark:hidden">
                  {total}
                </div>
                <div className="bubble-gradient bg-dark-300 bg-opacity-20 text-white w-7 h-7 leading-7 ml-2.5 rounded-full hidden dark:block">
                  {total}
                </div>
              </div>
            )}
            <div className="pl-5 chevron dark:text-dark-400 chevron-basket">
              <ChevronDown set="light" size="small" />
            </div>
          </div>
        }
        content={<ListScreens onClick={onClick} onRemove={onRemove} source={source} />}
      />
    </div>
  );
};

export default PublishMapList;
